.Join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j{
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.left-j>hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
  background: var(--planCard);
}

.left-j>div{
  display: flex;
  gap: 1rem;
}

.right-j{
  display: flex;
  position: absolute;
  height: 6rem;
  right: 12rem;
  align-items: center;
}
.email-container{
  top: -2rem;
  display: flex;
  gap: 3rem;
  padding: 1rem 2rem;
  background-color: gray;
}
.email-container>input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder{
  color: var(--lightgray);
}

.btn-j{
  color: var(--orange);
  background: transparent;
  border: 2px solid var(--orange);
}
.btn-j:hover{
  color: white;
  background: var(--orange);
}

@media screen and (max-width: 768px) {
  .Join{
    flex-direction: column;
    gap: 1rem;
  }
  .left-j {
    font-size: x-large;
    flex-direction: column;
  }
  .right-j{
    /* margin: 0rem 7rem; */
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-content: center;
    right: 0;
    /* height: 8rem; */
  }
}