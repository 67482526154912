.Footer-container{
  position: relative;

}

.Footer-container>hr{
  border: 1px solid var(--lightgray);
  background: var(--lightgray);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
.social-links {
  display: flex;
  gap: 4rem;
}

.social-links>img {
  width: 2em;
  height: 2rem;
  cursor: pointer;
}

.logo-f>img {
  width: 15rem;
}
.footer-blur-1 {
  width: 20rem;
  height: 20rem;
  bottom: -10rem;
  left: 0rem;
  filter: blur(280px);
  background: rgb(199, 24, 24);
}
.footer-blur-mid {
  width: 30rem;
  height: 30rem;
  left: 30rem;
  filter: blur(250px);
  top: 0rem;
}
.footer-blur-2 {
  width: 20rem;
  height: 20rem;
  bottom: -10rem;
  right: 0rem;
  filter: blur(270px);
  background: rgb(199, 24, 24);
}